import {Link} from 'react-router-dom'
import GoogleMap from './GoogleMapLocation'

interface SchoolDetailsProps {
  school_id: number
  school_name: string
  school_location: string
  school_email: string
  school_phone: string
  createdAt: string | null
  updatedAt: string
  school_location_latitude: number
  school_location_longitude: number
  principal_name: string
  principal_email: string
  principal_phone: string
  school_open_month: number
  school_close_month: number
}

export const Overview: React.FC<{schoolDetails: SchoolDetailsProps}> = ({schoolDetails}) => {
  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Profile Details</h3>
          </div>

          <Link to="/profile/settings" className="btn btn-primary align-self-center">
            Edit Profile
          </Link>
        </div>

        <div className="card-body p-9">
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">School Name</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">{schoolDetails?.school_name}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">School Email</label>

            <div className="col-lg-8 fv-row">
              <span className="fw-bold fs-6">{schoolDetails?.school_email}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">School Phone</label>

            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bolder fs-6 me-2">{schoolDetails?.school_phone}</span>

              {/* <span className="badge badge-success">Verified</span> */}
            </div>
          </div>

          {/* <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Company Site</label>

            <div className="col-lg-8">
              <a href="#" className="fw-bold fs-6 text-dark text-hover-primary">
                keenthemes.com
              </a>
            </div>
          </div> */}

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Principal Name</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">{schoolDetails?.principal_name}</span>
            </div>
          </div>
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Principal Email</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">{schoolDetails?.principal_email}</span>
            </div>
          </div>
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Principal Phone</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark me-2">
                {schoolDetails?.principal_phone}
              </span>
              {/* <span className="badge badge-success">Verified</span> */}
            </div>
          </div>

          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">School Location</label>

            <div className="col-lg-8">
              <span className="fw-bold fs-6">{schoolDetails?.school_location}</span>
            </div>
          </div>
          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">Open Month</label>

            <div className="col-lg-8">
              <span className="fw-bold fs-6">{schoolDetails?.school_open_month}</span>
            </div>
          </div>
          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">Close Month</label>

            <div className="col-lg-8">
              <span className="fw-bold fs-6">{schoolDetails?.school_close_month}</span>
            </div>
          </div>
          <div style={{height: '60vh'}}>
            {schoolDetails?.school_location_latitude && (
              <GoogleMap
                latitude={schoolDetails?.school_location_latitude}
                longitude={schoolDetails?.school_location_longitude}
                className="rounded-3"
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
