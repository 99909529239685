import React, {useEffect} from 'react'
import BusImg from '../../asset/images/Bus.png'

interface GoogleMapProps {
  locations: {
    latitude: number
    longitude: number
    busName: string
    busNumber: string
    driverName: string
  }[]
  center: {lat: number; lng: number}
  [key: string]: any
}
declare global {
  interface Window {
    google: any
  }
}

const GoogleMapMultiLocation: React.FC<GoogleMapProps> = ({
  locations,
  center,
  onMarkerClick,
  ...rest
}) => {
  useEffect(() => {
    if (!window.google) {
      console.error('Google Maps API is not loaded.')
      return
    }
    console.log(center)
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center,
      zoom: 19,
    })
    locations?.forEach((location, index) => {
      const marker = new window.google.maps.Marker({
        position: {lat: location.latitude, lng: location.longitude},
        map: map,
        icon: {
          url: BusImg,
          scaledSize: new window.google.maps.Size(60, 29),
          origin: new window.google.maps.Point(0, 0),
          // anchor: new window.google.maps.Point(20, 40),
        },
        title: location.busName,
      })
      const contentString = `
        <div class="container text-black ps-0">
          <div class="row">
            <div class="col">
              <p><strong>Bus Name:</strong> ${location.busName}</p>
              <p><strong>Bus Number:</strong> ${location.busNumber}</p>
              <p><strong>Driver Name:</strong> ${location.driverName}</p>
              <center class = 'pb-2 small'>Click for more details</center>
            </div>
          </div>
        </div>
      `

      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
      })

      marker.addListener('mouseover', () => {
        infoWindow.open(map, marker)
      })
      marker.addListener('click', () => {
        onMarkerClick(location)
      })

      marker.addListener('mouseout', () => {
        infoWindow.close()
      })
    })
  }, [center])

  return <div id="map" style={{width: '100%', minHeight: '100px', height: '100%'}} {...rest}></div>
}

export default GoogleMapMultiLocation
