const PokemonGo = ({...rest}) => (
  <div className="d-flex justify-content-center py-12" {...rest}>
    <div className="pokeball" />
  </div>
)
const ThunderStrike = ({...rest}) => (
  <div className="d-flex justify-content-center py-12" {...rest}>
    <div className="thunder" />
  </div>
)
const RunnerBall = ({...rest}) => (
  <div className="d-flex justify-content-center py-12" {...rest}>
    <div className="runner-ball" />
  </div>
)
export {PokemonGo, ThunderStrike, RunnerBall}
