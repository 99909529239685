import React from 'react'
import {Form as BSForm} from 'react-bootstrap'
import Input from './Input'
import Textarea from './Textarea'
import BuiltInSelect from './BuiltInSelect'
// import AdvancedSelect from './AdvancedSelect'
import Check from './Check'
import PhoneNumberInput from './PhoneNumberInput'
// import Tags from './Tags';
// import SelectWithAdd from './SelectWithAdd';
// import AdminProps from '../../views/admin/viewProps';
// import FacultyProps from '../../views/faculty/viewProps';
// import Time from './Time';
// import AsyncAdvancedSelect from './AsyncAdvancedSelect';
// import CreatableSelect from './CreatableSelect';

function FormikControl({control, children, ...rest}) {
  switch (control) {
    case 'input':
      return <Input {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
    case 'phone-input':
      return (
        <PhoneNumberInput {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
      )

    case 'textarea':
      return <Textarea {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
    case 'select':
      return (
        <BuiltInSelect {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass}>
          {children}
        </BuiltInSelect>
      )
    // case 'select-advanced':
    //   return (
    //     <AdvancedSelect {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
    //   )
    // case 'creatable-select':
    //   return (
    //     <CreatableSelect {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
    //   )
    // case 'async-select':
    //   return (
    //     <AsyncAdvancedSelect
    //       {...rest}
    //       ErrorFeedback={ErrorFeedback}
    //       validityClass={validityClass}
    //     />
    //   )
    // case 'select-add':
    //   return (
    //     <SelectWithAdd
    //       {...rest}
    //       ErrorFeedback={ErrorFeedback}
    //       validityClass={validityClass}
    //     />
    //   )
    case 'check':
      return <Check {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
    // case 'tags':
    //   return (
    //     <Tags
    //       {...rest}
    //       ErrorFeedback={ErrorFeedback}
    //       validityClass={validityClass}
    //     />
    //   )
    // case 'time':
    //   return (
    //     <Time
    //       {...rest}
    //       ErrorFeedback={ErrorFeedback}
    //       validityClass={validityClass}
    //     />
    //   )

    default:
      return <Input {...rest} ErrorFeedback={ErrorFeedback} validityClass={validityClass} />
  }
}

const validityClass = (error, value, touched) => {
  if (!error && value === 0) {
    return 'text-success'
  }
  if (!touched && !value) {
    return 'text-danger'
  }
  if (!!error || !value) {
    return 'text-danger'
  } else {
    return 'text-success'
  }
}
const ErrorFeedback = ({children}) => (
  <BSForm.Control.Feedback
    type="invalid"
    style={{top: 'auto', left: 'auto'}}
    className="bg-white text-danger d-block py-0 mt--0 px-0"
    tooltip
  >
    {children}
  </BSForm.Control.Feedback>
)

export default FormikControl
