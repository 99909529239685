import {useQuery} from 'react-query'
import {deleteShift, fetchShiftList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import ShiftForm from './Form'
import toast, {Toaster} from 'react-hot-toast'
import DeleteButton from '../../../components/DeleteButton'
import ModalWithForm from '../../../components/ModalWithForm'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'
import {formatTime} from '../../../helpers/dateFunctions'

const shiftBreadcrumbs = [
  {
    title: 'School Management',
    path: 'school-management/shift',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Shift() {
  const {data, isError, isFetching, refetch} = useQuery('classList', fetchShiftList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }
  const cellModifier = {
    starting_time: ({value}) => formatTime(value),
    ending_time: ({value}) => formatTime(value),
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Shift'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={ShiftForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteShift(row.original.shift_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }

  return (
    <>
      <PageTitle breadcrumbs={shiftBreadcrumbs}>Shift</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Shift.relevants}
          columnHeads={viewProps.Shift.columnHeads}
          name="Shift"
          Form={ShiftForm}
          formSize={{size: 'md'}}
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Shift'} onSubmit={refetch} Form={ShiftForm}>
              <Button>Add Shift</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default Shift
