import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchDriverList() {
  try {
    const response = await axios.get(API_URL + endpoints.driver.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createDriver(body) {
  try {
    const response = await axios.post(API_URL + endpoints.driver.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateDriver(body) {
  try {
    const response = await axios.post(API_URL + endpoints.driver.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteDriver(DriverId) {
  try {
    const response = await axios.delete(API_URL + endpoints.driver.delete + DriverId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
