import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchClassList() {
  try {
    const response = await axios.get(API_URL + endpoints.class.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createClass(body) {
  try {
    const response = await axios.put(API_URL + endpoints.class.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateClass(body) {
  try {
    const response = await axios.post(API_URL + endpoints.class.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteClass(classId) {
  try {
    const response = await axios.delete(API_URL + endpoints.class.delete + classId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
