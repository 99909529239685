import {Field, ErrorMessage, getIn} from 'formik'
import React from 'react'
import {Form as BSForm} from 'react-bootstrap'

function FormikInput({
  type = 'text',
  name,
  onChange,
  label,
  className = 'mb-9',
  size = 'md',
  required = false,
  ErrorFeedback,
  validityClass,
  fontSize,
  color,
  fontWeight,
  children,
  ...props
}) {
  return (
    <BSForm.Group className={className} controlId={name}>
      <Field name={name}>
        {({field, form: {errors, values, touched}}) => (
          <>
            <BSForm.Label className="fs-6 fw-bold">
              {label || name}
              {required && (
                <span
                  className={`ms-1 ${validityClass(
                    getIn(errors, name),
                    getIn(values, name),
                    getIn(touched, name)
                  )}`}
                >
                  *
                </span>
              )}
            </BSForm.Label>

            <BSForm.Control
              {...field}
              className="mb-0"
              required={required}
              type={type}
              size={size}
              {...props}
              onChange={(e) => {
                if (onChange) {
                  onChange(e)
                  return
                }
                field.onChange(e)
              }}
              autoComplete="off"
            />
          </>
        )}
      </Field>

      <ErrorMessage name={name} component={ErrorFeedback} />
    </BSForm.Group>
  )
}

export default FormikInput
