import {useQueries} from 'react-query'
import {fetchStudentTripDetails} from './api'
import * as Yup from 'yup'
import {createStudentTrip, updateStudentTrip} from './api'
import {fetchTripList} from '../trip/api'
import FormikControl from '../../../components/formikControl/FormikControl'
import {Form, Formik} from 'formik'
import toast from 'react-hot-toast'
import {RunnerBall} from '../../../components/FormLoader'

function TripDetais({initialValues, onCancel, onAfterSubmit}) {
  const calls = [
    {
      queryKey: 'tripDet',
      queryFn: () => fetchStudentTripDetails(initialValues.student_id),
      initialValues: [],
      refetchOnWindowFocus: false,
    },
    {queryKey: 'tripList', queryFn: fetchTripList, initialValues: [], refetchOnWindowFocus: false},
  ]
  const results = useQueries(calls)

  if (results[0].isFetching || results[1].isFetching) {
    return <RunnerBall />
  }

  const studentTrips = results[0]?.data.data || []
  const tripList = results[1]?.data.data || []

  const initialTripData = {
    pickup_trip_id: studentTrips.find((trip) => trip.trip_type === 'pickup')?.trip_id,
    student_pickup_trip_id: studentTrips.find((trip) => trip.trip_type === 'pickup')
      ?.student_bus_trip_id,
    drop_trip_id: studentTrips.find((trip) => trip.trip_type === 'drop')?.trip_id,
    student_drop_trip_id: studentTrips.find((trip) => trip.trip_type === 'drop')
      ?.student_bus_trip_id,
  }
  const pickUpTrips = tripList.filter((trip) => trip.trip_type === 'pickup')
  const dropTrips = tripList.filter((trip) => trip.trip_type === 'drop')

  const submitHandler = async (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    values.student_id = initialValues.student_id

    const toastId = toast.loading('Submitting')
    try {
      if (!initialTripData.pickup_trip_id) {
        await createStudentTrip({
          student_id: initialValues.student_id,
          trip_id: values.pickup_trip_id,
        })
      } else {
        await updateStudentTrip({
          student_bus_trip_id: initialTripData.student_pickup_trip_id,
          trip_id: values.pickup_trip_id,
        })
      }
      if (!initialTripData.drop_trip_id) {
        await createStudentTrip({
          student_id: initialValues.student_id,
          trip_id: values.drop_trip_id,
        })
      } else {
        await updateStudentTrip({
          student_bus_trip_id: initialTripData.student_drop_trip_id,
          trip_id: values.drop_trip_id,
        })
      }
      toast.success('Success', {id: toastId})
      setLoading(false)
      onAfterSubmit()
    } catch (error) {
      setLoading(false)
      toast.error('Something went wrong', {id: toastId})
    }
  }

  const formValues = initialTripData || {
    pickup_trip_id: '',
    drop_trip_id: '',
  }

  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          pickup_trip_id: Yup.string(),
          drop_trip_id: Yup.string(),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl control="select" name="pickup_trip_id" label="Pick Up">
              <option value="">Select pickup trip</option>
              {pickUpTrips.map((tripdet) => (
                <option value={tripdet.trip_id} key={tripdet.trip_id}>
                  {tripdet.trip_name}
                </option>
              ))}
            </FormikControl>
            <FormikControl control="select" name="drop_trip_id" label="Drop">
              <option value="">Select drop trip</option>
              {dropTrips.map((tripdet) => (
                <option value={tripdet.trip_id} key={tripdet.trip_id}>
                  {tripdet.trip_name}
                </option>
              ))}
            </FormikControl>

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TripDetais
