import {Field, ErrorMessage, getIn} from 'formik'
import React from 'react'
import {Form as BSForm} from 'react-bootstrap'

function Check({
  type = 'checkbox',
  label,
  options = [{key: 'Provide options', value: '1'}],
  name,
  className = 'mb-9',
  required = false,
  onChange,
  ErrorFeedback,
  validityClass,
  labelProvider,
  ...props
}) {
  return (
    <BSForm.Group className={`${className ? className : ''}`} controlId={name}>
      <Field name={name}>
        {({field, form: {values, errors, touched}}) => (
          <>
            <label className="fs-6 fw-bold">
              {label || name}
              {required && (
                <span
                  className={`ms-1 ${validityClass(
                    getIn(errors, name),
                    getIn(values, name),
                    getIn(touched, name)
                  )}`}
                >
                  *
                </span>
              )}
            </label>

            <div className="mt-2">
              {options.map((option) => (
                <BSForm.Check
                  {...field}
                  {...props}
                  key={option.value}
                  name={name}
                  value={option.value}
                  type={type}
                  inline
                  required={required}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e)
                      return
                    }
                    field.onChange(e)
                  }}
                  checked={
                    type === 'radio'
                      ? String(field.value) === String(option.value)
                      : String(field.value).includes(String(option.value))
                  }
                  label={option.key}
                  className="ps-9 pe-4 mb-0 form-check-sm"
                  id={name + option.key}
                />
              ))}
            </div>
          </>
        )}
      </Field>

      <ErrorMessage name={name} component={ErrorFeedback} />
    </BSForm.Group>
  )
}

export default Check
