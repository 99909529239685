import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createShift, updateShift} from './api'

function ShiftForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createShift : updateShift
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }
  const formValues = initialValues || {
    shift_name: '',
    starting_time: '',
    ending_time: '',
  }
  const timeStringToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours * 60 + minutes
  }

  const isEndTimeAfterStartTime = (endTime, startTime) => {
    return timeStringToMinutes(endTime) > timeStringToMinutes(startTime)
  }
  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          shift_name: Yup.string()
            .trim()
            .required('Shift Name is required')
            .notOneOf([''], 'Shift Name is Required'),
          starting_time: Yup.string()
            .trim()
            .required('Starting Time is required')
            .notOneOf([''], 'Starting Time is Required'),
          ending_time: Yup.string()
            .trim()
            .required('End Time is required')
            .notOneOf([''], 'End Time is Required')
            .test(
              'is-after-start',
              'End Time must be greater than Starting Time',
              function (value) {
                return isEndTimeAfterStartTime(value, this.parent.starting_time)
              }
            ),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl
              control="input"
              required
              placeholder="Shift Name"
              label="Shift Name"
              name="shift_name"
            />

            <FormikControl
              control="input"
              required
              placeholder="Start Time"
              label="Start Time"
              name="starting_time"
              type="time"
            />
            <FormikControl
              control="input"
              required
              placeholder="End Time"
              label="End Time"
              name="ending_time"
              type="time"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ShiftForm
