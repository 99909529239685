import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../_metronic/helpers'

function WithModal({
  renderModalBody = () => {},
  onHiding = () => {},
  modalTitle = 'Title',
  modalAttrs,
  children,
  disabled,
  disableHeader = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div
        type={`${disabled ? '' : 'button'}`}
        className="d-inline-block"
        style={{opacity: disabled ? '.5' : '1'}}
        onClick={() => {
          if (disabled) return
          setIsModalOpen(true)
        }}
      >
        {children}
      </div>

      <Modal
        centered
        show={isModalOpen}
        onHide={() => {
          onHiding()
          closeModal()
        }}
        backdrop="static"
        {...modalAttrs}
      >
        {!disableHeader && (
          <div
            className="p-7 border-bottom text-center align-items-center justify-content-between"
            style={{display: 'flex'}}
          >
            <h2 className="m-0 h2" style={{textAlign: 'start', width: '80%'}}>
              {modalTitle}
            </h2>
            <span onClick={closeModal} className="btn btn-icon btn-sm btn-active-icon-primary">
              <KTIcon
                iconName="cross"
                className="fs-1"
                style={{
                  width: '5%',
                  marginLeft: 'auto',
                }}
              />
            </span>
          </div>
        )}
        <Modal.Body className="p-4 p-md-4">{renderModalBody(closeModal)}</Modal.Body>
      </Modal>
    </>
  )
}

export default WithModal
