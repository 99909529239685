import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import Header from './Header'
import {useQuery} from 'react-query'
import {fetchSchoolDetails} from './api'
import {Processing} from '../../../components/Loaders'
import TopBarProgress from 'react-topbar-progress-indicator'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SchoolProfilePage: React.FC = () => {
  const {data, isError, isFetching, refetch} = useQuery('school_details', fetchSchoolDetails, {
    initialData: {},
    refetchOnWindowFocus: false,
  })

  const schoolDetails = data.data

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Header schoolName={schoolDetails?.school_name} />
              <Outlet />
            </>
          }
        >
          <Route
            path="/overview"
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
                <Overview schoolDetails={schoolDetails} />
              </>
            }
          />
          <Route
            path="/settings"
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Settings</PageTitle>
                {isFetching ? (
                  <Processing />
                ) : (
                  <Settings schoolDetails={schoolDetails} onAfterUpdate={refetch} />
                )}
              </>
            }
          />
          <Route index element={<Navigate to="/profile/overview" />} />
        </Route>
      </Routes>
      {isFetching && <TopBarProgress />}
    </>
  )
}

export default SchoolProfilePage
