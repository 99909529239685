import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {SchoolDetailsProps} from '../SettingsModel'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {updateSchoolDetails} from '../../../api'
import GoogleMap from '../../GoogleMapLocation'

const SchoolDetailsFormSchema = Yup.object().shape({
  school_name: Yup.string().required('School name is required'),
  school_location: Yup.string().required('School location is required'),
  school_email: Yup.string().email('Invalid email').required('Email is required'),
  school_phone: Yup.string().required('Phone number is required'),
  principal_name: Yup.string().required('Principal name is required'),
  principal_email: Yup.string().email('Invalid email').required('Principal email is required'),
  principal_phone: Yup.string().required('Principal phone number is required'),
  school_open_month: Yup.string().required('Open month is required'),
  school_close_month: Yup.string().required('Close month is required'),
  school_location_latitude: Yup.string(),
  school_location_longitude: Yup.string(),
})

const SchoolDetailsForm: React.FC<{initialValues: SchoolDetailsProps; onAfterUpdate: Function}> = ({
  initialValues,
  onAfterUpdate,
}) => {
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: SchoolDetailsFormSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await updateSchoolDetails(values)
      onAfterUpdate()
      setLoading(false)
    },
  })

  // const InputRow: React.FC<InputRowProps> = ({name, label, formik, ...rest}) => (
  //   <div className="row mb-6">
  //     <label className="col-lg-4 col-form-label fw-bold fs-6">
  //       <span className="required">{label}</span>
  //     </label>

  //     <div className="col-lg-8 fv-row">
  //       <input
  //         type="text"
  //         className="form-control form-control-lg form-control-solid"
  //         placeholder={label}
  //         {...formik.getFieldProps(name)}
  //         {...rest} // Spread the rest of the props here
  //       />
  //       {formik.touched[name] && formik.errors[name] && (
  //         <div className="fv-plugins-message-container">
  //           <div className="fv-help-block">{formik.errors[name]}</div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // )

  const handleSchoolLocationChange = (position: {latitude: number; longitude: number}) => {
    formik.setFieldValue('school_location_latitude', position.latitude)
    formik.setFieldValue('school_location_longitude', position.longitude)
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Profile Details</h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            {/* <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
              <div className="col-lg-8">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className="image-input-wrapper w-125px h-125px"
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`}}
                  ></div>
                </div>
              </div>
            </div> */}

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Name</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Name"
                  {...formik.getFieldProps('school_name')}
                />
                {formik.touched.school_name && formik.errors.school_name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Location</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Location"
                  {...formik.getFieldProps('school_location')}
                />
                {formik.touched.school_location && formik.errors.school_location && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_location}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Email</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="email"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Email"
                  {...formik.getFieldProps('school_email')}
                />
                {formik.touched.school_email && formik.errors.school_email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Phone</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="tel"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Phone"
                  {...formik.getFieldProps('school_phone')}
                />
                {formik.touched.school_phone && formik.errors.school_phone && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_phone}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Principal Name</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Principal Name"
                  {...formik.getFieldProps('principal_name')}
                />
                {formik.touched.principal_name && formik.errors.principal_name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.principal_name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Principal Email</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="email"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Principal Email"
                  {...formik.getFieldProps('principal_email')}
                />
                {formik.touched.principal_email && formik.errors.principal_email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.principal_email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Principal Phone</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="tel"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Principal Phone"
                  {...formik.getFieldProps('principal_phone')}
                />
                {formik.touched.principal_phone && formik.errors.principal_phone && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.principal_phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Open Month</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Open Month"
                  {...formik.getFieldProps('school_open_month')}
                />
                {formik.touched.school_open_month && formik.errors.school_open_month && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_open_month}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">School Close Month</span>
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="School Close Month"
                  {...formik.getFieldProps('school_close_month')}
                />
                {formik.touched.school_close_month && formik.errors.school_close_month && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.school_close_month}</div>
                  </div>
                )}
              </div>
            </div>

            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <span>Edit Location by dragging the marker</span>
            </label>
            <div style={{height: '60vh'}}>
              {initialValues && (
                <GoogleMap
                  latitude={formik.values?.school_location_latitude}
                  longitude={formik.values?.school_location_longitude}
                  draggable={true}
                  mutable={true}
                  onPositionChange={handleSchoolLocationChange}
                  className="rounded-3"
                />
              )}
            </div>
            <div className="d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className="indicator-progress" style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SchoolDetailsForm
