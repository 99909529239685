import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createTrip, updateTrip} from './api'
import {fetchDriverList} from '../driver/api'
import {fetchShiftList} from '../shift/api'
import {fetchRootList} from '../roots/api'
import {useQueries} from 'react-query'
import {RunnerBall} from '../../../components/FormLoader'
import {fetchBusList} from '../bus/api'

function TripForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const calls = [
    {queryKey: 'shift', queryFn: fetchShiftList, refetchOnWindowFocus: false},
    {queryKey: 'driver', queryFn: fetchDriverList, refetchOnWindowFocus: false},
    {queryKey: 'bus', queryFn: fetchBusList, refetchOnWindowFocus: false},
    {queryKey: 'routes', queryFn: fetchRootList, refetchOnWindowFocus: false},
  ]
  const results = useQueries(calls)

  if (
    results[0].isFetching ||
    results[1].isFetching ||
    results[2].isFetching ||
    results[3].isFetching
  ) {
    return <RunnerBall />
  }
  const shiftList = results[0]?.data?.data ?? []
  const driverList = results[1]?.data?.data ?? []
  const busList = results[2]?.data?.data ?? []
  const routeList = results[3]?.data?.data ?? []

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createTrip : updateTrip
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }
  const formValues = initialValues || {
    shift_id: '',
    driver_id: '',
    bus_id: '',
    route_id: '',
    start_time: '',
    end_time: '',
    trip_name: '',
    trip_status: '',
    trip_type: '',
  }
  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          trip_name: Yup.string()
            .trim()
            .required('Trip Name is required')
            .notOneOf([''], 'Trip Name is Required'),
          trip_status: Yup.string().required('Trip Status is required'),
          trip_type: Yup.string().required('Trip Type is required'),
          start_time: Yup.string().required('Start Time is required'),
          end_time: Yup.string().required('End Time is required'),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl
              control="input"
              required
              placeholder="Trip Name"
              label="Trip Name"
              name="trip_name"
            />
            <FormikControl control={'select'} name="trip_type" label="Trip Type">
              <option>Select Trip type</option>
              <option value="pickup">Pickup</option>
              <option value="drop">Drop</option>
            </FormikControl>
            <FormikControl
              control="input"
              required
              type="time"
              placeholder="Start Time"
              label="Start Time"
              name="start_time"
            />
            <FormikControl
              control="input"
              required
              type="time"
              placeholder="End Time"
              label="End Time"
              name="end_time"
            />
            <FormikControl
              control="select"
              required
              placeholder="Shift"
              label="Shift"
              name="shift_id"
            >
              <option value="">Select Shift</option>
              {shiftList.map((shift) => (
                <option value={shift.shift_id}>{shift.shift_name}</option>
              ))}
            </FormikControl>
            <FormikControl
              control="select"
              required
              placeholder="Driver"
              label="Driver"
              name="driver_id"
            >
              <option value="">Select driver</option>
              {driverList.map((driver) => (
                <option value={driver.driver_id}>{driver.driver_name}</option>
              ))}
            </FormikControl>
            <FormikControl control="select" required placeholder="Bus" label="Bus" name="bus_id">
              <option value="">Select bus</option>
              {busList.map((bus) => (
                <option value={bus.bus_id}>
                  {bus.bus_name} - {bus.bus_number}
                </option>
              ))}
            </FormikControl>
            <FormikControl
              control="select"
              required
              placeholder="Route"
              label="Route"
              name="route_id"
            >
              <option value="">Select Route</option>
              {routeList.map((route) => (
                <option value={route.route_id}>{route.route_name}</option>
              ))}
            </FormikControl>
            <FormikControl
              control="check"
              type="radio"
              options={[
                {key: 'Active', value: '1'},
                {key: 'Inactive', value: '0'},
              ]}
              required
              placeholder="Active Status"
              label="Active Status"
              name="trip_status"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TripForm
