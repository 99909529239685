import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchTripList() {
  try {
    const response = await axios.get(API_URL + endpoints.trip.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createTrip(body) {
  try {
    const response = await axios.post(API_URL + endpoints.trip.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateTrip(body) {
  try {
    const response = await axios.post(API_URL + endpoints.trip.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteTrip(tripId) {
  try {
    const response = await axios.delete(API_URL + endpoints.trip.delete + tripId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
