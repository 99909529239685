import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchShiftList() {
  try {
    const response = await axios.get(API_URL + endpoints.shift.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createShift(body) {
  try {
    const response = await axios.put(API_URL + endpoints.shift.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateShift(body) {
  try {
    const response = await axios.post(API_URL + endpoints.shift.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteShift(shiftId) {
  try {
    const response = await axios.delete(API_URL + endpoints.shift.delete + shiftId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
