import WithModal from './WithModal'

function ModalWithForm({
  name = '',
  initialValues,
  onSubmit = () => {},
  Form = <div></div>,
  children = <></>,
  ...rest
}) {
  return (
    <WithModal
      renderModalBody={(closeModal) => (
        <Form
          onAfterSubmit={() => {
            closeModal()
            onSubmit()
          }}
          initialValues={initialValues}
          onCancel={closeModal}
        />
      )}
      {...rest}
    >
      {children}
    </WithModal>
  )
}

export default ModalWithForm
