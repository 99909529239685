import {useQuery} from 'react-query'
import {deleteStudent, fetchStudentList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import StudentForm from './Form'
import toast, {Toaster} from 'react-hot-toast'
import DeleteButton from '../../../components/DeleteButton'
import ModalWithForm from '../../../components/ModalWithForm'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'
import WithModal from '../../../components/WithModal'
import TripDetais from './TripDetais'
import GuardianForm from '../guardian/Form'

const studentBreadcrumbs = [
  {
    title: 'User Management',
    path: 'users/student',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Student() {
  const {data, isError, isFetching, refetch} = useQuery('studentList', fetchStudentList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }

  const studentList = data?.data.forEach((student) => {
    if (student.dob === '01-01-0001') {
      student.dob = null
    }
  })

  const cellModifier = {
    student_status: ({value}) => {
      switch (Number(value)) {
        case 1:
          return 'Active'
        case 0:
          return 'Inactive'
        default:
          return ''
      }
    },
    trip: ({row}) => (
      <ModalWithForm
        modalTitle={'Trip Details of ' + row.original.student_name}
        initialValues={row.original}
        onSubmit={refetch}
        Form={TripDetais}
        // modalAttrs={{size: 'lg'}}
      >
        <span className="link-success">Trip Details</span>
      </ModalWithForm>
    ),
    parent: ({row}) => (
      <ModalWithForm
        modalTitle={'Guardian Details'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={GuardianForm}
        modalAttrs={{size: 'lg'}}
      >
        <span className="link-primary">Guardian Details</span>
      </ModalWithForm>
    ),
    dob: ({value}) => {
      if (value) {
        return new Date(value).toDateString()
      } else {
        return value
      }
    },
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Student'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={StudentForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteStudent(row.original.student_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }
  return (
    <>
      <PageTitle breadcrumbs={studentBreadcrumbs}>Student</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Student.relevants}
          columnHeads={viewProps.Student.columnHeads}
          name="Student"
          Form={StudentForm}
          formSize={{size: 'md'}}
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Student'} onSubmit={refetch} Form={StudentForm}>
              <Button>Add Student</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default Student
