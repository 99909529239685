const endpoints = Object.freeze({
  class: {
    list: '/api/admin/class',
    create: '/api/admin/class/create',
    update: '/api/admin/class/update',
    delete: '/api/admin/class/',
  },
  division: {
    list: '/api/admin/divisions',
    create: '/api/admin/divisions/create',
    update: '/api/admin/divisions/update',
    delete: '/api/admin/divisions/',
  },
  shift: {
    list: '/api/admin/shift',
    create: '/api/admin/shift/create',
    update: '/api/admin/shift/update',
    delete: '/api/admin/shift/',
  },
  holidays: {
    list: '/api/admin/holidays',
    create: '/api/admin/holidays/create',
    update: '/api/admin/holidays/update',
    delete: '/api/admin/holidays/',
  },
  events: {
    list: '/api/admin/events',
    create: '/api/admin/events/create',
    update: '/api/admin/events/update',
    delete: '/api/admin/events/',
  },
  bus: {
    list: '/api/admin/bus',
    create: '/api/admin/bus/create',
    update: '/api/admin/bus/update',
    delete: '/api/admin/bus/',
    addGPS: '/api/admin/bus/gps/',
    getGPS: '/api/admin/bus/gps/',
    addPIR: '/api/admin/bus/pir',
    getPIR: '/api/admin/bus/pir/',
  },
  busLocation: {
    list: '/api/admin/daily_trip',
  },
  driver: {
    list: '/api/admin/driver_list',
    create: '/api/admin/driver/create',
    update: '/api/admin/driver/update',
    delete: '/api/admin/driver/',
  },
  student: {
    list: '/api/admin/student',
    create: '/api/admin/student/create',
    update: '/api/admin/student/update',
    delete: '/api/admin/student/',
    student_trip: '/api/admin/student_trip/',
    student_trip_create: '/api/admin/student_trip/create',
    student_trip_update: '/api/admin/student_trip/update',
    student_guardian: '/api/admin/student_parent/',
  },
  guardian: {
    list: '/api/admin/student_parent/',
    create: '/api/admin/student_parent/create',
    update: '/api/admin/student_parent/update',
    delete: '/api/admin/student_parent/delete',
    setPrimary: '/api/admin/student_parent/set_primary',
  },
  trip: {
    list: '/api/admin/trip_list',
    create: '/api/admin/trip/create',
    update: '/api/admin/trip/update',
    delete: '/api/admin/trip/',
  },
  roots: {
    list: '/api/admin/routes',
    create: '/api/admin/routes/create',
    update: '/api/admin/routes/update',
    delete: '/api/admin/routes/',
  },
  profile: {
    schoolDetails: '/api/admin/school',
    schoolDetailUpdate: '/api/admin/school/update',
  },
})
export default endpoints
