/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon="bi-app-indicator"
      />
      {/* <SidebarMenuItem to="/builder" icon="switch" title="Layout Builder" fontIcon="bi-layers" />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Crafted</span>
        </div>
      </div> */}
      <SidebarMenuItem to="/profile/overview" icon="home" title="Profile" />
      <SidebarMenuItem to="daily-trips" icon="delivery-geolocation" title="Daily Trips" />

      <SidebarMenuItemWithSub
        to="school-management"
        title="School Management"
        fontIcon="bi-archive"
        icon="element-plus"
      >
        <SidebarMenuItem to="school-management/shift" title="Shift" hasBullet={true} />

        <SidebarMenuItem
          to="school-management/class"
          title="Class"
          hasBullet={true}
        ></SidebarMenuItem>
        <SidebarMenuItem
          to="school-management/division"
          title="Division"
          hasBullet={true}
        ></SidebarMenuItem>
        <SidebarMenuItem to="school-management/bus" title="Bus" hasBullet={true}></SidebarMenuItem>
        <SidebarMenuItem
          to="school-management/trip"
          title="Trip"
          hasBullet={true}
        ></SidebarMenuItem>
        <SidebarMenuItem
          to="school-management/routes"
          title="Routes"
          hasBullet={true}
        ></SidebarMenuItem>
        <SidebarMenuItem
          to="school-management/holidays"
          title="Holidays"
          hasBullet={true}
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/users"
        title="Users Management"
        fontIcon="bi-archive"
        icon="element-plus"
      >
        <SidebarMenuItem to="users/driver" title="Driver" hasBullet={true}></SidebarMenuItem>
        <SidebarMenuItem to="users/student" title="Student" hasBullet={true}></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to="/event-calendar" icon="element-plus" title="Event Calendar" />
    </>
  )
}

export {SidebarMenuMain}
