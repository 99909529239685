import LiveBusMap from './LiveBusMap'
import {fetchBusLocations} from './api'
import {useQueries} from 'react-query'
import {Processing} from '../../../components/Loaders'
import {PageTitle} from '../../../_metronic/layout/core'
import {fetchSchoolDetails} from '../profile/api'

const allBusLocationBreadCrumbs = [
  {
    title: 'All Bus Location',
    path: '/daily-trips',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DailyTrips = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`

  const calls = [
    {
      queryKey: 'busLocations',
      queryFn: () => fetchBusLocations(formattedDate),
      initialValues: [],
      refetchInterval: 2000,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'school_details',
      queryFn: fetchSchoolDetails,
      initialValues: {},
      refetchOnWindowFocus: false,
    },
  ]
  const results = useQueries(calls)
  console.log(results)

  const buses = results[0]?.data?.data
  const schoolDetails = results[1]?.data?.data

  return (
    <div>
      <PageTitle breadcrumbs={allBusLocationBreadCrumbs}>Daily Trips</PageTitle>
      {buses && (
        <LiveBusMap
          buses={buses}
          schoolLocation={{
            lat: schoolDetails?.school_location_latitude,
            lng: schoolDetails?.school_location_longitude,
          }}
        />
      )}
    </div>
  )
}

export default DailyTrips
