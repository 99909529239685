import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createStudent, updateStudent} from './api'
import {fetchShiftList} from '../shift/api'
import {fetchClassList} from '../class/api'
import {fetchDivisionList} from '../division/api'
import {useQueries} from 'react-query'
import {RunnerBall} from '../../../components/FormLoader'
import {checkIsoAndReturnYMDFormat} from '../../../helpers/dateFunctions'

function StudentForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const calls = [
    {queryKey: 'shift', queryFn: fetchShiftList, initialValues: [], refetchOnWindowFocus: false},
    {queryKey: 'class', queryFn: fetchClassList, initialValues: [], refetchOnWindowFocus: false},
    {
      queryKey: 'division',
      queryFn: fetchDivisionList,
      initialValues: [],
      refetchOnWindowFocus: false,
    },
  ]
  const results = useQueries(calls)

  if (results[0].isFetching || results[1].isFetching || results[2].isFetching) {
    return <RunnerBall />
  }

  const shiftList = results[0]?.data?.data
  const classList = results[1]?.data?.data
  const divisionList = results[2]?.data?.data

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    if (!values.dob) {
      values.dob = null
    }

    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createStudent : updateStudent
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }

  if (formType === 'update') {
    initialValues.class_name = initialValues.class
    initialValues.dob = checkIsoAndReturnYMDFormat(initialValues.dob)
  }

  const formValues = initialValues || {
    student_name: '',
    class_name: '',
    division: '',
    student_status: '',
    shift_id: '',
    gender: '',
    roll_number: '',
    dob: '',
    regID: '',
    pickUpNotificationTime: '',
    dropNotificationTime: '',
  }

  const preSelectedOptions = {
    class: classList.find((cls) => cls.class_name === initialValues?.class),
    division: divisionList.find((div) => div.division_name === initialValues?.division),
    shift: shiftList.find((shift) => Number(shift.shift_id) === Number(initialValues?.shift_id)),
  }

  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          student_name: Yup.string().required(),
          class_name: Yup.string().required(),
          division: Yup.string().required(),
          student_status: Yup.string().required(),
          shift_id: Yup.string().required(),
          gender: Yup.string(),
          roll_number: Yup.string(),
          dob: Yup.date().nullable(),
          regID: Yup.string().required(),
          pickUpNotificationTime: Yup.number().required(),
          dropNotificationTime: Yup.number().required(),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting, errors}) => (
          <Form>
            <FormikControl
              control="input"
              required
              placeholder="Student Name"
              label="Student Name"
              name="student_name"
            />
            <FormikControl
              control="select"
              required
              placeholder="Class Name"
              label="Class Name"
              name="class_name"
            >
              <option value="">Select Class</option>
              {classList.map((cls) => (
                <option value={cls.class_name}>{cls.class_name}</option>
              ))}
            </FormikControl>
            <FormikControl
              control="select"
              required
              placeholder="Division Name"
              label="Division Name"
              name="division"
            >
              <option value="">Select Division</option>
              {divisionList.map((cls) => (
                <option value={cls.division_name}>{cls.division_name}</option>
              ))}
            </FormikControl>
            <FormikControl
              control="select"
              required
              placeholder="Shift"
              label="Shift"
              name="shift_id"
            >
              <option value="">Select Shift</option>
              {shiftList.map((cls) => (
                <option value={cls.shift_id}>{cls.shift_name}</option>
              ))}
            </FormikControl>
            <FormikControl
              control="input"
              placeholder="Roll Number"
              label="Roll Number"
              name="roll_number"
            />
            <FormikControl
              control="input"
              required
              placeholder="Reg ID"
              label="Reg ID"
              name="regID"
            />
            <FormikControl
              control="input"
              type="number"
              required
              placeholder="Pick Up Notification Time"
              label="Pick Up Notification Time"
              name="pickUpNotificationTime"
            />
            <FormikControl
              control="input"
              type="number"
              required
              placeholder="Drop Notification Time"
              label="Drop Notification Time"
              name="dropNotificationTime"
            />
            <FormikControl control="input" type="date" placeholder="DOB" label="DOB" name="dob" />
            <FormikControl
              control="check"
              type="radio"
              options={[
                {key: 'Male', value: 'male'},
                {key: 'Female', value: 'female'},
              ]}
              placeholder="Gender"
              label="Gender"
              name="gender"
            />
            <FormikControl
              control="check"
              type="radio"
              options={[
                {key: 'Active', value: '1'},
                {key: 'Inactive', value: '0'},
              ]}
              required
              placeholder="Active Status"
              label="Active Status"
              name="student_status"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StudentForm
