import LiveBusRouteMap from './LiveBusRouteMap'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {fetchBusLocations} from '../dailyTrips/api'
import {useQuery} from 'react-query'

const dailyTripBreadcrumbs = [
  {
    title: 'Daily Trips',
    path: '/daily-trips',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const BusLiveLocation = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`

  const {state: route} = useLocation()
  const {data, refetch} = useQuery('busLocations', () => fetchBusLocations(formattedDate), {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  const busRoute =
    data[0]?.data.find((routes) => {
      return route.bus_registration_number === routes.bus_registration_number
    }) || route

  return (
    <>
      <PageTitle breadcrumbs={dailyTripBreadcrumbs}>
        {route.bus_name + ' - ' + route.bus_registration_number}
      </PageTitle>

      <div className="d-flex">
        <div className="w-75">
          <LiveBusRouteMap
            route={busRoute}
            currentLocation={{
              lat: busRoute?.current_latitude,
              lng: busRoute?.current_longitude,
            }}
            refetch={refetch}
          />
        </div>
        <div className="w-25">
          <div className="card h-100 rounded-start-0">
            <div className="card-body">
              <h5 className="card-title">Trip Details</h5>
              <p className="card-text">Trip Name: {route.trip_name}</p>
              <p className="card-text">Trip Type: {route.trip_type}</p>
              <p className="card-text">Bus Name: {route.bus_name}</p>
              <p className="card-text">Bus Number: {route.bus_number}</p>
              <p className="card-text">Bus Registration Number: {route.bus_registration_number}</p>
              <p className="card-text">Start Location: {route.start_location}</p>
              <p className="card-text">Trip Start Time: {route.trip_start_time}</p>
              <p className="card-text">Trip End Time: {route.trip_end_time}</p>
              <p className="card-text">Driver Name: {route.driver_name}</p>
              <p className="card-text">Driver Mobile: {route.driver_mobile}</p>
              <p className="card-text">Shift Name: {route.shift_name}</p>
              <p className="card-text">Shift Starting Time: {route.shift_starting_time}</p>
              <p className="card-text">Shift Ending Time: {route.shift_ending_time}</p>
              <p className="card-text">Trip Started Time: {route.trip_started_time}</p>
              <p className="card-text">Trip Ended Time: {route.trip_ended_time}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BusLiveLocation
