import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createDivision, updateDivision} from './api'

function DivisionForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createDivision : updateDivision
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }
  const formValues = initialValues || {
    division_name: '',
    division_status: '1',
  }
  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          division_name: Yup.string()
            .trim()
            .required('Division Name is required')
            .notOneOf([''], 'Division Name is Required'),
          division_status: Yup.string().required('Division Status is required'),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl
              control="input"
              required
              placeholder="Division Name"
              label="Division Name"
              name="division_name"
            />
            <FormikControl
              control="check"
              type="radio"
              options={[
                {key: 'Active', value: '1'},
                {key: 'Inactive', value: '0'},
              ]}
              required
              placeholder="Active Status"
              label="Active Status"
              name="division_status"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DivisionForm
