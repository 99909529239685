import {SchoolDetailsProps} from './SettingsModel'
import ProfileDetails from './cards/ProfileDetails'
// import {SignInMethod} from './cards/SignInMethod'
// import {ConnectedAccounts} from './cards/ConnectedAccounts'
// import {EmailPreferences} from './cards/EmailPreferences'
// import {Notifications} from './cards/Notifications'
// import {DeactivateAccount} from './cards/DeactivateAccount'

export const Settings: React.FC<{schoolDetails: SchoolDetailsProps; onAfterUpdate: Function}> = ({
  schoolDetails,
  onAfterUpdate,
}) => {
  return (
    <>
      <ProfileDetails initialValues={schoolDetails} onAfterUpdate={onAfterUpdate} />
      {/* <SignInMethod />
      <ConnectedAccounts />
      <EmailPreferences />
      <Notifications />
      <DeactivateAccount /> */}
    </>
  )
}
