import {Col, Row} from 'react-bootstrap'
import FormikControl from '../../../components/formikControl/FormikControl'
import {getCountryCallingCode} from 'react-phone-number-input'

function GuardianFields({index, setFieldValue, values, setPrimaryGuardianErr}) {
  return (
    <Row>
      <Col md="4">
        <FormikControl
          control="input"
          disabled
          required
          placeholder="Student"
          label="Student"
          name={`guardians.${index}.student_name`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Guardian Name "
          label="Guardian Name "
          name={`guardians.${index}.parent_name`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Email"
          label="Email"
          name={`guardians.${index}.parent_email`}
          type="email"
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="phone-input"
          // required
          placeholder="Enter Mobile number"
          label="Mobile No"
          name={`guardians.${index}.parent_mobile`}
          onCountryChange={(value) => {
            let code
            if (value) {
              code = getCountryCallingCode(value)
            } else {
              code = ''
            }
            setFieldValue(`guardians.${index}.parent_country_code`, code)
          }}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="check"
          type="radio"
          required
          placeholder="Active Status"
          label="Active Status"
          name={`guardians.${index}.parent_user_status`}
          options={[
            {key: 'Active', value: '1'},
            {key: 'Inactive', value: '0'},
          ]}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="check"
          type="radio"
          required
          placeholder="Gender"
          label="Gender"
          name={`guardians.${index}.parent_gender`}
          options={[
            {key: 'Male', value: 'Male'},
            {key: 'Female', value: 'Female'},
          ]}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Date of Birth"
          label="Date of Birth"
          name={`guardians.${index}.parent_dob`}
          type="date"
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          // required
          placeholder="Country"
          label="Country"
          name={`guardians.${index}.parent_country`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          // required
          placeholder="Whatsapp No"
          label="Whatsapp No"
          name={`guardians.${index}.parent_whatsapp`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Identification Id"
          label="Identification Id"
          name={`guardians.${index}.parent_identification_id`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Relation"
          label="Relation"
          name={`guardians.${index}.parent_relation`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="input"
          required
          placeholder="Address"
          label="Address"
          name={`guardians.${index}.parent_address`}
        />
      </Col>
      <Col md="4">
        <FormikControl
          control="check"
          type="radio"
          required
          options={[
            {key: 'Yes', value: '1'},
            {key: 'No', value: '0'},
          ]}
          onChange={(e) => {
            setPrimaryGuardianErr('')
            setFieldValue(`guardians.${index}.is_primary`, e.target.value)
          }}
          label="Is Primary"
          name={`guardians.${index}.is_primary`}
        />
      </Col>
    </Row>
  )
}

export default GuardianFields
