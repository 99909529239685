import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchBusList() {
  try {
    const response = await axios.get(API_URL + endpoints.bus.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createBus(body) {
  try {
    const response = await axios.put(API_URL + endpoints.bus.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to create data')
  }
}
export async function updateBus(body) {
  try {
    const response = await axios.post(API_URL + endpoints.bus.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to update data')
  }
}
export async function deleteBus(busId) {
  try {
    const response = await axios.delete(API_URL + endpoints.bus.delete + busId)
    return response.data
  } catch (error) {
    throw new Error('Unable to delete data')
  }
}
export async function addGPStoBus(payload) {
  try {
    const response = await axios.put(API_URL + endpoints.bus.addGPS, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to put data')
  }
}
export async function getGPS(busId) {
  try {
    const response = await axios.get(API_URL + endpoints.bus.getGPS + busId)
    return response.data
  } catch (error) {
    throw new Error('Unable to get data')
  }
}
export async function addPIRtoBus(payload) {
  try {
    const response = await axios.put(API_URL + endpoints.bus.addPIR, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to put data')
  }
}
export async function getPIR(busId) {
  try {
    const response = await axios.get(API_URL + endpoints.bus.getPIR + busId)
    return response.data
  } catch (error) {
    throw new Error('Unable to get data')
  }
}
