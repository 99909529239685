import React, {useEffect, useState} from 'react'
import BusImg from '../../asset/images/Bus.png'

interface GoogleMapProps {
  startLocationLatitude: number
  startLocationLongitude: number
  endLocationLatitude: number
  endLocationLongitude: number
  currentLocation: {lat: number; lng: number}
  [key: string]: any
}

declare global {
  interface Window {
    google: any
  }
}

const GoogleMapBusRoute: React.FC<GoogleMapProps> = ({
  startLocationLatitude,
  startLocationLongitude,
  endLocationLatitude,
  endLocationLongitude,
  currentLocation,
  refetch,
  ...rest
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [busMarker, setBusMarker] = useState<google.maps.Marker | null>(null)

  useEffect(() => {
    if (!window.google) {
      console.error('Google Maps API is not loaded.')
      return
    }

    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: currentLocation,
      zoom: 15,
    })

    const busMarkerInstance = new window.google.maps.Marker({
      position: currentLocation,
      map: mapInstance,
      icon: {
        url: BusImg,
        scaledSize: new window.google.maps.Size(60, 29),
        origin: new window.google.maps.Point(0, 0),
      },
    })

    setMap(mapInstance)
    setBusMarker(busMarkerInstance)

    const updateBusLocation = () => {
      refetch().then((res: Response) => {
        console.log(res)
        const updatedLocation = {lat: currentLocation.lat + 0.001, lng: currentLocation.lng + 0.001} // Example: Incrementing the coordinates

        // Update marker position
        if (busMarkerInstance) {
          busMarkerInstance.setPosition(updatedLocation)
        }
      })
    }

    // Call updateBusLocation function periodically to fetch updated bus location
    const intervalId = setInterval(updateBusLocation, 2000) // Update every 5 seconds

    return () => {
      // Clean up interval when component unmounts
      clearInterval(intervalId)
    }
  }, [])

  return <div id="map" style={{width: '100%', minHeight: '100px', height: '100%'}} {...rest}></div>
}

export default GoogleMapBusRoute
