import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'

interface GoogleMapProps {
  latitude: number
  longitude: number
  draggable?: boolean
  onPositionChange?: (position: {latitude: number; longitude: number}) => void
  mutable?: boolean
  [key: string]: any
}

declare global {
  interface Window {
    google: any // Define the type of the 'google' property
  }
}

const GoogleMap: React.FC<GoogleMapProps> = ({
  latitude,
  longitude,
  draggable = false,
  onPositionChange,
  mutable = false,
  ...rest
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [marker, setMarker] = useState<google.maps.Marker | null>(null)

  useEffect(() => {
    if (!window.google) {
      console.error('Google Maps API is not loaded.')
      return
    }

    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: {lat: latitude, lng: longitude},
      zoom: 15,
    })

    const markerInstance = new window.google.maps.Marker({
      position: {lat: latitude, lng: longitude},
      map: mapInstance,
      draggable: draggable,
    })

    setMap(mapInstance)
    setMarker(markerInstance)

    if (draggable && onPositionChange) {
      markerInstance.addListener('dragend', () => {
        const newPosition = markerInstance.getPosition()
        const newLatitude = newPosition.lat()
        const newLongitude = newPosition.lng()
        onPositionChange({latitude: newLatitude, longitude: newLongitude})
      })
    }
  }, [])

  const goToCurrentLocation = () => {
    if (navigator.geolocation && map) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {latitude: currentLat, longitude: currentLng} = position.coords
          map.setCenter({lat: currentLat, lng: currentLng})
          marker?.setPosition({lat: currentLat, lng: currentLng})
          onPositionChange?.({latitude: currentLat, longitude: currentLng})
        },
        (error) => console.error('Error getting current location:', error)
      )
    }
  }

  return (
    <div
      style={{width: '100%', height: '100%', position: 'relative'}}
      {...rest}
      className="position-relative"
    >
      <div id="map" style={{width: '100%', height: '100%'}}></div>
      {mutable && (
        <Button
          variant="secondary"
          className="position-absolute bottom-0 px-3 py-2 rounded-0 text-white border border-white"
          onClick={goToCurrentLocation}
        >
          Set Current Location
        </Button>
      )}
    </div>
  )
}

export default GoogleMap
