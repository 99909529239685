import {useState} from 'react'
import {Button} from 'react-bootstrap'
import WithModal from './WithModal'

const DeleteConfirmationModal = ({message = '', onDelete = () => {}, children}) => {
  const [isBusy, setIsBusy] = useState(false)

  return (
    <WithModal
      modalAttrs={{size: 'sm'}}
      modalTitle={<div className="text-danger">Are You Sure?</div>}
      renderModalBody={(closeModal) => (
        <>
          <div className="mt-2 mb-5 text-center fs-6">
            {message || 'You are about to delete a record.'}
          </div>
          <div className="d-flex justify-content-between p-4">
            <Button
              style={{width: '45%'}}
              variant="danger"
              disabled={isBusy}
              onClick={() => {
                setIsBusy(true)
                onDelete().then((res) => {
                  setIsBusy(false)
                  closeModal()
                })
              }}
            >
              Delete
            </Button>
            <Button
              disabled={isBusy}
              style={{width: '45%'}}
              variant="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    >
      {children}
    </WithModal>
  )
}

export default DeleteConfirmationModal
