const viewProps = Object.freeze({
  Class: {
    name: 'Class',
    relevants: ['class_name', 'class_status', 'Edit', 'Delete'],
    columnHeads: ['Class', 'Status', 'Edit', 'Delete'],
  },
  Division: {
    name: 'Division',
    relevants: ['division_name', 'division_status', 'Edit', 'Delete'],
    columnHeads: ['Division', 'Status', 'Edit', 'Delete'],
  },
  Shift: {
    name: 'Shift',
    relevants: ['shift_name', 'starting_time', 'ending_time', 'Edit', 'Delete'],
    columnHeads: ['Shift', 'Starting Time', 'End Time', 'Edit', 'Delete'],
  },
  Holiday: {
    name: 'Holidays',
    relevants: ['holiday_date', 'reason', 'academic_year', 'Edit', 'Delete'],
    columnHeads: ['holiday date', 'Occasion', 'academic year', 'Edit', 'Delete'],
  },
  Bus: {
    name: 'Bus',
    relevants: [
      'bus_name',
      'bus_number',
      'bus_status',
      'PIR',
      'GPS',
      'bus_registration_number',
      'bus_insurance_expiry',
      'bus_polution_expiry',
      'bus_last_service',
      'Edit',
      'Delete',
    ],
    columnHeads: [
      'bus name',
      'bus number',
      'bus status',
      'PIR',
      'GPS',
      'registration number',
      'insurance expiry',
      'polution expiry',
      'last service',
      'Edit',
      'Delete',
    ],
  },
  Trip: {
    name: 'Trip',
    relevants: [
      'trip_name',
      'trip_type',
      'start_time',
      'end_time',
      'bus_name',
      'driver_name',
      'shift_name',
      'route_name',
      'trip_status',
      'Edit',
      'Delete',
    ],
    columnHeads: [
      'trip name',
      'trip type',
      'start time',
      'end time',
      'bus',
      'driver',
      'shift',
      'route',
      'trip status',
      'Edit',
      'Delete',
    ],
  },
  Roots: {
    name: 'Routes',
    relevants: ['route_name', 'Edit', 'Delete'],
    columnHeads: ['Route name', 'Edit', 'Delete'],
  },
  Driver: {
    name: 'Driver',
    relevants: [
      'driver_name',
      // 'profile_pic',
      'country_code',
      'driver_phone',
      'driver_status',
      'email',
      'country',
      'license',
      'license_expiry_date',
      'passport',
      'passport_expiry',
      'Passport_country',
      'visa',
      'visa_expiry',
      'gender',
      'dob',
      'whatsapp',
      'identification_id',
      'Edit',
      'Delete',
    ],
    columnHeads: [
      'driver name',
      // 'profile pic',
      // "user id",
      'country code',
      'driver phone',
      'driver status',
      'email',
      'country',
      'license',
      'license expiry date',
      'passport',
      'passport expiry',
      'Passport country',
      'visa',
      'visa expiry',
      'gender',
      'dob',
      'whatsapp',
      'identification id',
      'Edit',
      'Delete',
    ],
  },

  Student: {
    name: 'Student',
    relevants: [
      'student_name',
      'class',
      'division',
      'roll_number',

      // 'pickUpNotificationTime',
      // 'dropNotificationTime',
      'shift_name',
      'trip',
      'parent',
      // 'bus_name',
      'student_status',
      'gender',
      // 'bus_number',
      // 'bus_registration_number',
      'dob',
      // 'location_name',
      // 'location_latitude',
      // 'location_longitude',
      // 'location_status',
      // 'is_default',
      // 'route_name',
      'Edit',
      'Delete',
    ],
    columnHeads: [
      'student name',
      'class',
      'division',
      'roll number',
      // 'pickUp Notification Time',
      // 'drop Notification Time',
      'shift name',
      'Trip Details',
      'Guardian Details',
      // 'bus name',
      'student status',
      'gender',
      // 'bus number',
      // 'bus registration number',
      'dob',
      // 'location name',
      // 'location latitude',
      // 'location longitude',
      // 'location status',
      // 'is default',
      // 'route name',
      'Edit',
      'Delete',
    ],
  },
})
export default viewProps
