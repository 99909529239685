import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createBus, updateBus} from './api'

function BusForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createBus : updateBus
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }
  const formValues = initialValues || {
    bus_name: '',
    bus_status: '1',
    bus_number: '',
    bus_registration_number: '',
    bus_insurance_expiry: '',
    bus_polution_expiry: '',
    bus_last_service: '',
  }
  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          bus_name: Yup.string()
            .trim()
            .required('Bus Name is required')
            .notOneOf([''], 'Bus Name is Required'),
          bus_number: Yup.string().required('Bus number is required'),
          bus_registration_number: Yup.string().required('Bus Registration number is required'),
          bus_insurance_expiry: Yup.date()/*.required('Insurance expiry date is required')*/,
          bus_polution_expiry: Yup.date()/*.required('Pollution expiry date is required')*/,
          bus_last_service: Yup.date()/*.required('Last service date is required')*/,
          bus_status: Yup.string().required('Bus Status is required').required(),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl
              control="input"
              required
              placeholder="Bus Name"
              label="Bus Name"
              name="bus_name"
            />
            <FormikControl
              control="input"
              required
              placeholder="Bus Number"
              label="Bus Number"
              name="bus_number"
            />
            <FormikControl
              control="input"
              required
              placeholder="Bus Registration Number"
              label="Bus Registration Number"
              name="bus_registration_number"
            />

            <FormikControl
              control="input"
              type="date"
              placeholder="Insurance Expiry Date"
              label="Insurance Expiry Date"
              name="bus_insurance_expiry"
            />
            <FormikControl
              control="input"
              type="date"              
              placeholder="Pollution Expiry Date"
              label="Pollution Expiry Date"
              name="bus_polution_expiry"
            />
            <FormikControl
              control="input"
              type="date"              
              placeholder="Last Service Date"
              label="Last Service Date"
              name="bus_last_service"
            />
            <FormikControl
              control="check"
              type="radio"
              options={[
                {key: 'Active', value: '1'},
                {key: 'Inactive', value: '0'},
              ]}
              required
              placeholder="Active Status"
              label="Active Status"
              name="bus_status"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BusForm
