import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {createDriver, updateDriver} from './api'
import {Col, Row} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {RunnerBall} from '../../../components/FormLoader'
import {fetchBusList} from '../bus/api'

function DriverForm({initialValues, onCancel, onAfterSubmit}) {
  const formType = initialValues ? 'update' : 'add'

  const {data, isFetching} = useQuery('buslist', fetchBusList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')
    const action = formType === 'add' ? createDriver : updateDriver
    action(values)
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }

  if (formType === 'update') {
    initialValues.name = initialValues.driver_name
    initialValues.mobile = initialValues.driver_phone
    initialValues.user_status = initialValues.driver_status
    initialValues.blood_group = 'ABC'
    initialValues.address = 'Address'
  }

  const formValues = initialValues || {
    name: '',
    email: '',
    mobile: '',
    country_code: '',
    user_status: '',
    gender: '',
    dob: '',
    country: '',
    whatsapp: '',
    identification_id: '',
    blood_group: 'ABC',
    address: 'Address',
    bus_id: '0',
    license: '',
    license_expiry_date: '',
    passport: '',
    passport_expiry: '',
    Passport_country: '',
    visa: '',
    visa_expiry: '',
  }
  if (isFetching) {
    return <RunnerBall />
  }

  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          name: Yup.string()
            .trim()
            .required('Class Name is required')
            .notOneOf([''], 'Class Name is Required'),
          email: Yup.string().email('Invalid mail'),
          mobile: Yup.string()
            .trim()
            .required('Mobile Number is required')
            .notOneOf([''], 'Mobile Number is Required'),
          country_code: Yup.string()
            .trim()
            .required('Country Code is required')
            .notOneOf([''], 'Country Code is Required'),
          user_status: Yup.string().required('Status is required'),
          gender: Yup.string(),
          dob: Yup.date(),
          country: Yup.string(),
          whatsapp: Yup.string(),
          identification_id: Yup.string(),
          // blood_group: Yup.string()
          //   .trim()
          //   .required('Blood Group is required')
          //   .notOneOf([''], 'Blood Group is Required'),
          // address: Yup.string()
          //   .trim()
          //   .required('Address is required')
          //   .notOneOf([''], 'Address is Required'),
          // bus_id: Yup.string().required(),
          license: Yup.string(),
          license_expiry_date: Yup.string(),
          passport: Yup.string(),
          passport_expiry: Yup.string(),
          Passport_country: Yup.string(),
          visa_expiry: Yup.string(),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <Row>
              <Col md="6">
                <FormikControl
                  control="input"
                  required
                  placeholder="Driver Name"
                  label="Driver Name"
                  name="name"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  type="email"
                  placeholder="Email"
                  label="Email"
                  name="email"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  required
                  placeholder="Mobile Number"
                  label="Mobile Number"
                  name="mobile"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  required
                  placeholder="Country Code"
                  label="Country Code"
                  name="country_code"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="check"
                  type="radio"
                  options={[
                    {key: 'Active', value: '1'},
                    {key: 'Inactive', value: '0'},
                  ]}
                  required
                  placeholder="Active Status"
                  label="Active Status"
                  name="user_status"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="check"
                  type="radio"
                  options={[
                    {key: 'Male', value: 'male'},
                    {key: 'Female', value: 'female'},
                  ]}
                  placeholder="Gender"
                  label="Gender"
                  name="gender"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  type="date"
                  placeholder="Date of Birth"
                  label="Date of Birth"
                  name="dob"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="Country"
                  label="Country"
                  name="country"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="Whatsapp Number"
                  label="Whatsapp Number"
                  name="whatsapp"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="Identification Id"
                  label="Identification Id"
                  name="identification_id"
                />
              </Col>
              {/* <Col md="6">
                <FormikControl
                  control="select"
                  required
                  placeholder="Blood Group"
                  label="Blood Group"
                  name="blood_group"
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </FormikControl>
              </Col> */}
              {/* <Col md="6">
                <FormikControl
                  control="textarea"
                  required
                  placeholder="Address"
                  label="Address"
                  name="address"
                />
              </Col> */}
              {/* <Col md="6">
                <FormikControl
                  control="select"
                  placeholder="Bus"
                  required
                  label="Bus"
                  name="bus_id"
                >
                  <option value="">Select Bus</option>
                  {data?.data?.map((bus) => (
                    <option value={bus.bus_id}>{bus.bus_name}</option>
                  ))}
                </FormikControl>
              </Col> */}
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="License"
                  label="License"
                  name="license"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  type="date"
                  placeholder="License Expiry Date"
                  label="License Expiry Date"
                  name="license_expiry_date"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="Passport"
                  label="Passport"
                  name="passport"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  type="date"
                  placeholder="Passport Expiry Date"
                  label="Passport Expiry Date"
                  name="passport_expiry"
                />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  placeholder="Passport Country"
                  label="Passport Country"
                  name="Passport_country"
                />
              </Col>
              <Col md="6">
                <FormikControl control="input" placeholder="Visa" label="Visa" name="visa" />
              </Col>
              <Col md="6">
                <FormikControl
                  control="input"
                  type="date"
                  placeholder="Visa Expiry Date"
                  label="Visa Expiry Date"
                  name="visa_expiry"
                />
              </Col>

              <div className="text-center pt-10">
                <button
                  type="reset"
                  onClick={onCancel}
                  className="btn btn-light me-3"
                  disabled={isSubmitting}
                >
                  Discard
                </button>

                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  <span>Submit</span>
                </button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DriverForm
