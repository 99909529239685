import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchDivisionList() {
  try {
    const response = await axios.get(API_URL + endpoints.division.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createDivision(body) {
  try {
    const response = await axios.put(API_URL + endpoints.division.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateDivision(body) {
  try {
    const response = await axios.post(API_URL + endpoints.division.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteDivision(divisionId) {
  try {
    const response = await axios.delete(API_URL + endpoints.division.delete + divisionId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
