import {useMemo, useRef} from 'react'
import {Pagination} from 'react-bootstrap'
// import WithModal from '../WithModal'
import {useTable, useSortBy, useGlobalFilter, usePagination} from 'react-table'
// import { GlobalFilter } from './GlobalFilter';
// import Loader from '../Loader';
import TableTemplate from './TableTemplate'
import {GlobalFilter} from './GlobalFilter'

const CRUDTable = (props) => {
  const {
    loading = false,
    columnHeads = [],
    relevants = [],
    Form = () => <div></div>,
    // irremovable = false,
    // immutable = false,
    // insertable = true,
    cellModifier = {},
    // addFormProps = {},
    // updateFormProps = {},
    formSize = 'sm',
    response = [],
    // reFetch = () => {},
    // addBtnLabel = '',
    headerExtras = <></>,
    noDataMessage = 'No Records Found',
    // downloadable = true,
  } = props

  const columnData = useRef(
    relevants.map((element, i) => {
      const temp = {
        Header: columnHeads[i] ?? 'Missing Column Head',
        accessor: element,
      }

      Object.keys(cellModifier).forEach((key) => {
        if (element === key) {
          temp.Cell = (value) => {
            return cellModifier[key](value) ?? ''
          }
        }
      })

      return temp
    })
  )

  const columns = useMemo(() => columnData.current, [])

  const data = useMemo(() => response, [response])

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance

  const {globalFilter, pageIndex} = state

  let modalSize = {}

  if (typeof formSize === typeof '') {
    modalSize.add = formSize
    modalSize.update = formSize
  } else {
    modalSize = {...formSize}
  }

  let formType = {}

  if (typeof Form === typeof function () {}) {
    formType.add = Form
    formType.update = Form
  } else if (Object.keys(Form).length === 1) {
    formType.add = Form.add || Form.update
    formType.update = Form.add || Form.update
  } else {
    formType = {...Form}
  }

  let errorMessage = ''

  if (!loading) {
    if (response.length === 0) {
      errorMessage = noDataMessage
    }
  }

  return (
    <div className="bg-body rounded-3 p-10">
      <div className="d-flex align-items-center">
        <span className="me-auto">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </span>
        {headerExtras}
        {/* {!!errorMessage
          ? ''
          : downloadable && (
              <ExcelFile
                element={
                  <span type="button" className="mx-3">
                    <Download />
                  </span>
                }
                filename={name}
              >
                <ExcelSheet
                  data={response}
                  name={name.length > 30 ? 'Data' : name} //name length must be less than 30 chars
                >
                  {relevants.map((relevant, index) => {
                    if (relevant === 'More') {
                      return <ExcelColumn key={index} />
                    }
                    return (
                      <ExcelColumn
                        key={index}
                        label={columnHeads[index] ? columnHeads[index] : 'Missing column head'}
                        value={relevant}
                      />
                    )
                  })}
                </ExcelSheet>
              </ExcelFile>
            )} */}
      </div>

      <div className="p-0">
        {!!errorMessage ? (
          <div className="text-center h1 py-4 text-muted">{errorMessage}</div>
        ) : (
          <>
            <TableTemplate
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              prepareRow={prepareRow}
            />
            <div className="d-flex justify-content-center pt-3">
              <Pagination size="sm">
                <Pagination.First disabled={!canPreviousPage} onClick={() => gotoPage(0)} />

                <Pagination.Prev disabled={!canPreviousPage} onClick={() => previousPage()} />

                <Pagination.Item>
                  {pageIndex + 1} of {pageOptions.length}
                </Pagination.Item>

                <Pagination.Next disabled={!canNextPage} onClick={() => nextPage()} />

                <Pagination.Last disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)} />
              </Pagination>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CRUDTable
