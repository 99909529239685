import {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

const DeleteGuardianConfirm = ({onDelete = async () => {}, onCancel, ...rest}) => {
  const [isBusy, setIsBusy] = useState(false)

  return (
    <Modal size="sm" {...rest}>
      <Modal.Header>
        <div className="text-danger">Are You Sure?</div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="mt-2 mb-5 text-center fs-6">You are about to delete a record.</div>
          <div className="d-flex justify-content-between p-4">
            <Button
              style={{width: '45%'}}
              variant="danger"
              disabled={isBusy}
              onClick={() => {
                setIsBusy(true)
                onDelete()
                  .then((res) => {
                    setIsBusy(false)
                  })
                  .catch((err) => {
                    setIsBusy(false)
                  })
              }}
            >
              Delete
            </Button>
            <Button disabled={isBusy} style={{width: '45%'}} variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteGuardianConfirm
