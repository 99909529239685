import {useQuery} from 'react-query'
import {deleteBus, fetchBusList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import BusForm from './Form'
import toast, {Toaster} from 'react-hot-toast'
import DeleteButton from '../../../components/DeleteButton'
import ModalWithForm from '../../../components/ModalWithForm'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'
import GPS from './GPS'
import PIR from './PIR'

const busBreadcrumbs = [
  {
    title: 'School Management',
    path: 'school-management/bus',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Bus() {
  const {data, isError, isFetching, refetch} = useQuery('busList', fetchBusList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }
  const cellModifier = {
    bus_status: ({value}) => {
      switch (Number(value)) {
        case 1:
          return 'Active'
        case 0:
          return 'Inactive'
        default:
          return ''
      }
    },
    GPS: ({row}) => {
      return (
        <ModalWithForm
          modalTitle={'GPS of ' + row.original.bus_name}
          initialValues={row.original}
          Form={GPS}
          modalAttrs={{size: 'sm'}}
        >
          <i className="fa fs-2x fa-map-location-dot text-hover-gray-700"></i>
        </ModalWithForm>
      )
    },
    PIR: ({row}) => {
      return (
        <ModalWithForm
          modalTitle={'PIR of ' + row.original.bus_name}
          initialValues={row.original}
          Form={PIR}
          modalAttrs={{size: 'sm'}}
        >
          <i className="bi bi-radioactive fs-1 text-hover-gray-700"></i>
        </ModalWithForm>
      )
    },
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Bus'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={BusForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteBus(row.original.bus_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }

  return (
    <>
      <PageTitle breadcrumbs={busBreadcrumbs}>Bus</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Bus.relevants}
          columnHeads={viewProps.Bus.columnHeads}
          name="Bus"
          Form={BusForm}
          formSize={{size: 'md'}}
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Bus'} onSubmit={refetch} Form={BusForm}>
              <Button>Add Bus</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default Bus
