import {useQuery} from 'react-query'
import {deleteClass, fetchClassList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import ClassForm from './Form'
import toast, {Toaster} from 'react-hot-toast'
import DeleteButton from '../../../components/DeleteButton'
import ModalWithForm from '../../../components/ModalWithForm'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'

const classBreadcrumbs = [
  {
    title: 'School Management',
    path: 'school-management/class',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function ClassView() {
  const {data, isError, isFetching, refetch} = useQuery('classList', fetchClassList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }
  const cellModifier = {
    class_status: ({value}) => {
      switch (Number(value)) {
        case 1:
          return 'Active'
        case 0:
          return 'Inactive'
        default:
          return ''
      }
    },
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Class'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={ClassForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteClass(row.original.class_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }

  return (
    <>
      <PageTitle breadcrumbs={classBreadcrumbs}>Class</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Class.relevants}
          columnHeads={viewProps.Class.columnHeads}
          name="Class"
          Form={ClassForm}
          formSize={{size: 'md'}}
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Class'} onSubmit={refetch} Form={ClassForm}>
              <Button>Add Class</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default ClassView
