import {Field, ErrorMessage, getIn} from 'formik'
import React from 'react'
import {Form as BSForm} from 'react-bootstrap'

function BuiltInSelect({
  name,
  label,
  children,
  onChange,
  className = 'mb-9',
  size = 'md',
  required = false,
  ErrorFeedback,
  validityClass,
  labelProvider,
  ...props
}) {
  return (
    <BSForm.Group className={className} controlId={name}>
      <Field name={name}>
        {({field, form: {errors, touched, values}}) => (
          <>
            <BSForm.Label className="fs-6 fw-bold">
              {label || name}
              {required && (
                <span
                  className={`ms-1 ${validityClass(
                    getIn(errors, name),
                    getIn(values, name),
                    getIn(touched, name)
                  )}`}
                >
                  *
                </span>
              )}
            </BSForm.Label>

            <BSForm.Control
              as="select"
              {...field}
              className="mb-0"
              size={size}
              {...props}
              required={required}
              onChange={(e) => {
                if (onChange) {
                  onChange(e)
                  return
                }
                field.onChange(e)
              }}
            >
              {children}
            </BSForm.Control>
          </>
        )}
      </Field>

      <ErrorMessage name={name} component={ErrorFeedback} />
    </BSForm.Group>
  )
}
export default BuiltInSelect
