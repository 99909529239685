import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchBusLocations(date) {
  try {
    const response = await axios.put(API_URL + endpoints.busLocation.list, {
      dateby: date,
    })
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
