import {useState} from 'react'
import {useAsyncDebounce} from 'react-table'
import {KTIcon} from '../../_metronic/helpers'

export const GlobalFilter = ({filter, setFilter, ...props}) => {
  const [inputValue, setInputValue] = useState(filter)

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 400)

  return (
    <div className="d-flex align-items-center position-relative my-1">
      <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
      <input
        type="text"
        className="form-control form-control-solid w-250px ps-14"
        placeholder="Search"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}
