import GoogleMapMultiLocation from './GoogleMapLocationMultiMarker'
import Bus from '../../asset/images/Bus.png'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

const LiveBusMap = ({buses, schoolLocation}) => {
  const [center, setCenter] = useState(schoolLocation)

  const navigate = useNavigate()

  return (
    <div style={{height: '100vh', width: '100%'}} className="d-flex">
      <div className="w-75 h-100">
        <GoogleMapMultiLocation
          center={center}
          locations={buses?.map((bus) => ({
            latitude: bus.current_latitude,
            longitude: bus.current_longitude,
            busName: bus.bus_name,
            busNumber: bus.bus_number,
            driverName: bus.driver_name,
          }))}
          onMarkerClick={(markerData) => {
            const clickedBus = buses?.find(
              (bus) =>
                bus.current_latitude === markerData.latitude &&
                bus.current_longitude === markerData.longitude
            )
            navigate('/track-bus', {state: clickedBus})
          }}
        />
      </div>
      <div className="w-25 px-3">
        <div
          className={`d-flex bg-body rounded mb-2 ${
            center.lat === schoolLocation.lat &&
            center.lng === schoolLocation.lng &&
            'shadow-sm bg-light-danger'
          }`}
          role="button"
          onClick={() => {
            setCenter(schoolLocation)
          }}
        >
          <div className="p-4 fs-2 text-center">
            <i className="fa-solid fa-school fs-2hx text-primary"></i>{' '}
            <span className="ps-2">Locate School</span>
          </div>
        </div>
        {buses?.map((bus) => (
          <div
            className={`d-flex bg-body rounded mb-2 ${
              center.lat === bus.current_latitude &&
              center.lng === bus.current_longitude &&
              'shadow-sm bg-light-danger'
            }`}
            role="button"
            onClick={() => {
              setCenter({
                lat: bus.current_latitude,
                lng: bus.current_longitude,
              })
            }}
          >
            <div className="p-2 align-self-center">
              <img src={Bus} alt="Bus Icon" style={{width: '50px'}} />
            </div>
            <div className="p-2">
              <div className="pb-2">{bus.bus_name}</div>
              <div>Bus Number: {bus.bus_number}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LiveBusMap
