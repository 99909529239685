import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/formikControl/FormikControl'
import toast from 'react-hot-toast'
import {addPIRtoBus, getPIR} from './api'
import {useQuery} from 'react-query'
import {RunnerBall} from '../../../components/FormLoader'

function PIR({initialValues: busDetails, onCancel, onAfterSubmit}) {
  const {data, isFetching} = useQuery('pir', () => getPIR(busDetails.bus_id), {
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <RunnerBall />
  }

  const device = data?.data

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const toastId = toast.loading('Submitting')

    addPIRtoBus({
      bus_id: values.bus_id,
      device_id: values.device_id,
    })
      .then(() => {
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((err) => {
        toast.error('Something went wrong', {id: toastId})
      })
      .then((res) => setLoading(false))
  }
  const formValues = {
    device_id: device?.device_id || '',
    bus_name: busDetails.bus_name,
    bus_id: busDetails.bus_id,
  }
  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          device_id: Yup.string()
            .trim()
            .required('PIR device Id is required')
            .notOneOf([''], 'PIR device Id is Required'),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting}) => (
          <Form>
            <FormikControl
              control="input"
              placeholder="Bus Name"
              label="Bus Name"
              name="bus_name"
              disabled
            />
            <FormikControl
              control="input"
              required
              placeholder="Device Id"
              label="Device Id"
              name="device_id"
            />

            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PIR
