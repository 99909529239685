import DeleteConfirmationModal from './DeleteWarning'

function DeleteButton({...rest}) {
  return (
    <DeleteConfirmationModal {...rest}>
      <span role="button" className="ps-3">
        <i className="bi bi-trash text-danger fs-3 p-0"></i>
      </span>
    </DeleteConfirmationModal>
  )
}

export default DeleteButton
