import {useQuery} from 'react-query'
import {deleteTrip, fetchTripList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import TripForm from './Form'
import toast, {Toaster} from 'react-hot-toast'
import DeleteButton from '../../../components/DeleteButton'
import ModalWithForm from '../../../components/ModalWithForm'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'
import {formatTime} from '../../../helpers/dateFunctions'

const tripBreadcrumbs = [
  {
    title: 'School Management',
    path: 'school-management/trip',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Trip() {
  const {data, isError, isFetching, refetch} = useQuery('tripList', fetchTripList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }
  const cellModifier = {
    trip_status: ({value}) => {
      switch (Number(value)) {
        case 1:
          return 'Active'
        case 0:
          return 'Inactive'
        default:
          return ''
      }
    },

    start_time: ({value}) => formatTime(value),
    end_time: ({value}) => formatTime(value),
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Trip'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={TripForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteTrip(row.original.trip_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }

  return (
    <>
      <PageTitle breadcrumbs={tripBreadcrumbs}>Trip</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Trip.relevants}
          columnHeads={viewProps.Trip.columnHeads}
          name="Trip"
          Form={TripForm}
          formSize={{size: 'md'}}
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Trip'} onSubmit={refetch} Form={TripForm}>
              <Button>Add Trip</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default Trip
