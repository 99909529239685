import {FieldArray} from 'formik'
import {Button, CloseButton, Col, Row} from 'react-bootstrap'
// import GradientBtnOrange from "./GradientBtnOrange";

function FieldArrayWithLayout(props) {
  const {
    children = () => {},
    name = '',
    title = '',
    initialValues = {},
    array = [],
    max = Infinity,
    onRemoveClick,
  } = props

  return (
    <FieldArray name={name}>
      {(arrayHelpers) =>
        array.map((obj, index) => (
          <Row className=" w-100 mx-0 mt-2 pb-3" key={index}>
            <Col md={12} className="py-5 mb-2 text-info fs-2 text-center">
              {title} {index + 1}
              {array.length !== 1 && (
                <CloseButton
                  className="float-end"
                  style={{fontSize: '12px'}}
                  onClick={() => {
                    onRemoveClick
                      ? onRemoveClick(obj, () => arrayHelpers.remove(index))
                      : arrayHelpers.remove(index)
                  }}
                />
              )}
            </Col>

            {children({index, arrayHelpers, obj})}

            <Col xs={12}>
              {array.length === index + 1 && (
                <Button
                  disabled={max === array.length}
                  onClick={() => arrayHelpers.push(initialValues)}
                  className="py-2 px-3 float-end"
                  title={max === array.length ? 'Reached maximum limit' : ''}
                >
                  <span className="small fw-bold">Add {title}</span>
                </Button>
              )}
            </Col>
          </Row>
        ))
      }
    </FieldArray>
  )
}

export default FieldArrayWithLayout
