export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  var d = new Date(str)
  return d.toISOString() === str
}
export const checkIsoAndReturnYMDFormat = (str) => {
  if (isIsoDate(str)) {
    return str.slice(0, 10)
  } else {
    return str
  }
}

export const getDays = (startDate, endDate) => {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  const diffTime = date2 - date1
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export function formatTime(timeString) {
  // Parse the time string into a Date object
  const time = new Date(`2000-01-01T${timeString}`)

  // Extract hours, minutes, and seconds
  let hours = time.getHours()
  const minutes = time.getMinutes().toString().padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'

  // Convert hours to 12-hour format
  hours %= 12
  hours = hours || 12 // Convert 0 to 12

  // Format the time to HH:MM AM/PM format
  const formattedTime = `${hours}:${minutes} ${ampm}`
  return formattedTime
}
