import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchRootList() {
  try {
    const response = await axios.get(API_URL + endpoints.roots.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createRoot(body) {
  try {
    const response = await axios.put(API_URL + endpoints.roots.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateRoot(body) {
  try {
    const response = await axios.put(API_URL + endpoints.roots.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteRoot(rootId) {
  try {
    const response = await axios.delete(API_URL + endpoints.roots.delete + rootId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
