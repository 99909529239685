import GoogleMapBusRoute from './GoogleMapBusRoute'

const LiveBusRouteMap = ({route, currentLocation, refetch}) => {
  return (
    <div style={{height: '100%', width: '100%'}} className="d-flex">
      <GoogleMapBusRoute
        currentLocation={currentLocation}
        startLocationLatitude={route.start_location_latitude}
        startLocationLongitude={route.start_location_longitude}
        endLocationLatitude={route.end_location_latitude + 1}
        endLocationLongitude={route.end_location_longitude + 2}
        refetch={refetch}
      />
    </div>
  )
}

export default LiveBusRouteMap
