import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchStudentList() {
  try {
    const response = await axios.get(API_URL + endpoints.student.list)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createStudent(body) {
  try {
    const response = await axios.put(API_URL + endpoints.student.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateStudent(body) {
  try {
    const response = await axios.post(API_URL + endpoints.student.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteStudent(studentId) {
  try {
    const response = await axios.delete(API_URL + endpoints.student.delete + studentId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function fetchStudentTripDetails(studentId) {
  try {
    const response = await axios.get(API_URL + endpoints.student.student_trip + studentId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createStudentTrip(payload) {
  try {
    const response = await axios.put(API_URL + endpoints.student.student_trip_create, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateStudentTrip(payload) {
  try {
    const response = await axios.put(API_URL + endpoints.student.student_trip_update, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function fetchStudentGuardianDetails(studentId) {
  try {
    const response = await axios.get(API_URL + endpoints.student.student_guardian + studentId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
