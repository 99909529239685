import {Field, ErrorMessage, getIn} from 'formik'
import React from 'react'
import {Form as BSForm} from 'react-bootstrap'

function TextArea({
  name,
  resize = true,
  onChange,
  label,
  height = '105px',
  required = false,
  className = 'mb-9',
  ErrorFeedback,
  validityClass,
  labelProvider,
  ...props
}) {
  return (
    <BSForm.Group className={`${className ? className : ''}`} controlId={name}>
      <Field name={name}>
        {({field, form: {errors, touched, values}}) => (
          <>
            <BSForm.Label className="fs-6 fw-bold">
              {label || name}
              {required && (
                <span
                  className={`ms-1 ${validityClass(
                    getIn(errors, name),
                    getIn(values, name),
                    getIn(touched, name)
                  )}`}
                >
                  *
                </span>
              )}
            </BSForm.Label>

            <BSForm.Control
              as="textarea"
              {...field}
              onChange={(e) => {
                if (onChange) {
                  onChange(e)
                  return
                }
                field.onChange(e)
              }}
              className="mb-0"
              {...props}
              required={required}
              autoComplete="off"
              style={{
                resize: resize ? 'block' : 'none',
                height: height,
              }}
            />
          </>
        )}
      </Field>

      <ErrorMessage name={name} component={ErrorFeedback} />
    </BSForm.Group>
  )
}
export default TextArea
