import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {
  createGuardian,
  deleteGuardian,
  fetchGuardianList,
  setPrimaryGuardian,
  updateGuardian,
} from './api'
import {useQuery} from 'react-query'
import {RunnerBall} from '../../../components/FormLoader'
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import GuardianFields from './GuardianFields'
import FieldArrayWithLayout from '../../../components/FieldArrayWithLayout'
import {useState} from 'react'
import DeleteGuardianConfirm from './DeleteGuardianConfirm'

function GuardianForm({initialValues: studentDetails, onCancel, onAfterSubmit}) {
  const {data, isFetching} = useQuery(
    'guardians',
    () => fetchGuardianList(studentDetails.student_id),
    {
      refetchOnWindowFocus: false,
    }
  )

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [primaryGuardianErr, setPrimaryGuardianErr] = useState('')
  const [removeGuardianFromForm, setRemoveGuardianFromForm] = useState(() => {})

  if (isFetching) {
    return <RunnerBall />
  }
  const existingGuardianDetails = data?.data

  const initialFormValues = {
    parent_name: '',
    parent_email: '',
    parent_mobile: '',
    parent_country_code: '',
    parent_user_status: '',
    parent_gender: '',
    parent_dob: '',
    parent_country: '',
    parent_whatsapp: '',
    parent_identification_id: '',
    parent_relation: '',
    parent_address: '',
    student_name: studentDetails.student_name,
    student_id: studentDetails.student_id,
  }

  const isExistingGuardian = (guardian) => (guardian?.parent_id ? true : false)

  const submitHandler = (values, {setSubmitting: setLoading}) => {
    setLoading(true)
    const primaryGuardians = values.guardians.filter((guardian) => guardian.is_primary === '1')
    if (primaryGuardians.length > 1) {
      setPrimaryGuardianErr('Only one guardian can be marked as primary')
      setLoading(false)
      return
    }
    if (primaryGuardians.length === 0) {
      setPrimaryGuardianErr('At least one guardian must be marked as primary')
      setLoading(false)
      return
    }
    const toastId = toast.loading('Submitting')

    const submitGuardian = async (guardian) => {
      const submitType = isExistingGuardian(guardian) ? 'update' : 'create'

      const action = submitType === 'update' ? updateGuardian : createGuardian

      const payload = {
        name: guardian.parent_name,
        relation: guardian.parent_relation,
        mobile: guardian.parent_mobile.replace('+' + guardian.parent_country_code, ''),
        email: guardian.parent_email,
        country_code: guardian.parent_country_code,
        user_status: guardian.parent_user_status,
        gender: guardian.parent_gender,
        dob: guardian.parent_dob,
        country: guardian.parent_country,
        whatsapp: guardian.parent_whatsapp,
        identification_id: guardian.parent_identification_id,
        address: guardian.parent_address,
        student_id: studentDetails.student_id,
      }
      if (submitType === 'update') {
        payload.user_id = guardian.parent_user_id
      }

      return action(payload)
    }

    const submitAllGuardians = async () => {
      const promises = values.guardians.map((guardian) => submitGuardian(guardian))

      const results = await Promise.all(promises)

      return results
    }

    submitAllGuardians()
      .then(async (results) => {
        try {
          const latestGuardianDets = await fetchGuardianList(studentDetails.student_id)
          latestGuardianDets.data?.forEach(async (guardian, i) => {
            const guardianDetails = {
              student_id: studentDetails.student_id,
              parent_id: guardian.parent_id,
              user_id: guardian.parent_user_id,
              is_primary: Number(values.guardians[i].is_primary),
            }

            await setPrimaryGuardian(guardianDetails)
          })
        } catch (error) {
          toast.error('Something went wrong while setting primary parent', {id: toastId})
        }
        setLoading(false)
        toast.success('Success', {id: toastId})
        onAfterSubmit()
      })
      .catch((error) => {
        console.error('Error submitting guardians:', error)
        setLoading(false)
        toast.error('Something went wrong', {id: toastId})
      })
  }

  existingGuardianDetails.forEach((guardian) => {
    guardian.student_id = studentDetails.student_id
    guardian.student_name = studentDetails.student_name
  })

  const formValues = {
    guardians: [],
  }

  if (existingGuardianDetails[0].parent_id) {
    existingGuardianDetails.forEach((guardian, i) => {
      formValues.guardians[i] = {...guardian}
      formValues.guardians[i].parent_mobile =
        '+' + guardian.parent_country_code + guardian.parent_mobile
    })
  } else {
    formValues.guardians = [initialFormValues]
  }

  return (
    <div className="px-5 pb-3">
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          guardians: Yup.array().of(
            Yup.object().shape({
              student_name: Yup.string(),
              parent_name: Yup.string()
                .trim()
                .required('Guardian Name is required')
                .notOneOf([''], 'Guardian Name is Required'),
              parent_email: Yup.string().email().required('Email is required'),
              parent_mobile: Yup.string()
                // .required('Mobile is required')
                .test('parent_mobile', 'Enter a valid phone number', (value) => {
                  if (value) {
                    return isPossiblePhoneNumber(value)
                  }
                  return false
                }), //Gender,dob, identification id, address,email
              parent_country_code: Yup.string(),
              parent_user_status: Yup.string().required('Status is required'),
              parent_gender: Yup.string().required('Gender is required'),
              parent_dob: Yup.date().required('DOB is required'),
              is_primary: Yup.boolean(),
              parent_country: Yup.string(),
              parent_whatsapp: Yup.string(),
              parent_identification_id: Yup.string()
                .trim()
                .required('Identification Id is required')
                .notOneOf([''], 'Identification Id is Required'),
              parent_relation: Yup.string()
                .trim()
                .required('Relation is required')
                .notOneOf([''], 'Relation is Required'),
              parent_address: Yup.string()
                .trim()
                .required('Address is required')
                .notOneOf([''], 'Address is Required'),
            })
          ),
        })}
        onSubmit={submitHandler}
      >
        {({isSubmitting, values, setFieldValue}) => (
          <Form>
            <FieldArrayWithLayout
              name="guardians"
              initialValues={initialFormValues}
              array={values.guardians}
              title="Guardian"
              max={2}
              onRemoveClick={async (guardian, removeFromFormValues) => {
                if (isExistingGuardian(guardian)) {
                  setShowDeleteConfirm(true)
                  setRemoveGuardianFromForm({removeFromFormValues, guardianToRemove: guardian})
                } else {
                  removeFromFormValues()
                }
              }}
            >
              {({index}) => (
                <GuardianFields
                  setPrimaryGuardianErr={setPrimaryGuardianErr}
                  index={index}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
            </FieldArrayWithLayout>
            {primaryGuardianErr && (
              <div className=" text-danger text-center">{primaryGuardianErr}</div>
            )}
            <DeleteGuardianConfirm
              onCancel={() => setShowDeleteConfirm(false)}
              onDelete={async () => {
                const toastId = toast.loading('Deleting...')
                try {
                  await deleteGuardian({
                    student_id: removeGuardianFromForm.guardianToRemove.student_id,
                    parent_id: removeGuardianFromForm.guardianToRemove.parent_id,
                    user_id: removeGuardianFromForm.guardianToRemove.parent_user_id,
                  })
                  removeGuardianFromForm.removeFromFormValues()
                  toast.success('Deleted successfully', {id: toastId})
                  setShowDeleteConfirm(false)
                } catch (error) {
                  toast.error('Something went wrong, try again later', {id: toastId})
                }
              }}
              show={showDeleteConfirm}
            />
            <div className="text-center pt-10">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-light me-3"
                disabled={isSubmitting}
              >
                Discard
              </button>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                <span>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default GuardianForm
