import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ClassView from '../pages/class/Index'
import Division from '../pages/division/Index'
import Trip from '../pages/trip/Index'
import Bus from '../pages/bus/Index'
import Student from '../pages/student/Index'
import Driver from '../pages/driver/Index'
import Shift from '../pages/shift/Index'
import Roots from '../pages/roots/Index'
import SchoolProfilePage from '../pages/profile/Index'
import DailyTrips from '../pages/dailyTrips/Index'
import BusLiveLocation from '../pages/busLiveLocation/Index'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const Holiday = lazy(() => import('../pages/holiday/Index'))
  const EventCalendar = lazy(() => import('../pages/event-calendar/Index'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* <Route path="builder" element={<BuilderPageWrapper />} /> */}
        <Route path="school-management/class" element={<ClassView />} />
        <Route path="school-management/division" element={<Division />} />
        <Route path="school-management/shift" element={<Shift />} />
        <Route path="school-management/bus" element={<Bus />} />
        <Route path="school-management/trip" element={<Trip />} />
        <Route path="school-management/routes" element={<Roots />} />
        <Route path="daily-trips" element={<DailyTrips />} />
        <Route path="track-bus" element={<BusLiveLocation />} />
        <Route path="users/driver" element={<Driver />} />
        <Route path="users/student" element={<Student />} />
        <Route path="school-management/holidays" element={<Holiday />} />
        {/* Lazy Modules */}
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <SchoolProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="event-calendar"
          element={
            <SuspensedView>
              <EventCalendar />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
