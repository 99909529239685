import axios from 'axios'
import endpoints from '../../endpoints'

const API_URL = process.env.REACT_APP_API_URL

export async function fetchGuardianList(studentId) {
  try {
    const response = await axios.get(API_URL + endpoints.guardian.list + studentId)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function createGuardian(body) {
  try {
    const response = await axios.put(API_URL + endpoints.guardian.create, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function updateGuardian(body) {
  try {
    const response = await axios.post(API_URL + endpoints.guardian.update, body)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function deleteGuardian(payload) {
  try {
    const response = await axios.post(API_URL + endpoints.guardian.delete, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
export async function setPrimaryGuardian(payload) {
  try {
    const response = await axios.post(API_URL + endpoints.guardian.setPrimary, payload)
    return response.data
  } catch (error) {
    throw new Error('Unable to fetch data')
  }
}
