import {useQuery} from 'react-query'
import {deleteRoot, fetchRootList} from './api'
import {PageTitle} from '../../../_metronic/layout/core'
import {Error500} from '../../modules/errors/components/Error500'
import CRUDTable from '../../../components/table/CRUDTable'
import toast, {Toaster} from 'react-hot-toast'
import viewProps from '../viewProps'
import {Processing} from '../../../components/Loaders'
import RootForm from './Form'
import ModalWithForm from '../../../components/ModalWithForm'
import DeleteButton from '../../../components/DeleteButton'
import EditBtn from '../../../components/EditBtn'
import {Button} from 'react-bootstrap'

const routeBreadcrumbs = [
  {
    title: 'School Management',
    path: 'school-management/routes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Roots() {
  const {data, isError, isFetching, refetch} = useQuery('routesList', fetchRootList, {
    initialData: [],
    refetchOnWindowFocus: false,
  })

  if (isFetching) {
    return <Processing />
  }

  const cellModifier = {
    Edit: ({row}) => (
      <ModalWithForm
        modalTitle={'Update Route'}
        initialValues={row.original}
        onSubmit={refetch}
        Form={RootForm}
      >
        <EditBtn />
      </ModalWithForm>
    ),
    Delete: ({row}) => (
      <DeleteButton
        onDelete={async () => {
          const toastId = toast.loading('Deleting Please wait...')
          try {
            await deleteRoot(row.original.route_id)
            await refetch()
            toast.success('Deleted Successfully', {id: toastId})
          } catch (error) {
            toast.error('Something went wrong, please try again later')
          }
        }}
      />
    ),
  }

  return (
    <>
      <PageTitle breadcrumbs={routeBreadcrumbs}>Routes</PageTitle>
      {isError ? (
        <div className="text-center">
          <Error500 />
        </div>
      ) : (
        <CRUDTable
          response={data?.data || []}
          relevants={viewProps.Roots.relevants}
          columnHeads={viewProps.Roots.columnHeads}
          name="Routes"
          cellModifier={cellModifier}
          headerExtras={
            <ModalWithForm modalTitle={'Add Route'} onSubmit={refetch} Form={RootForm}>
              <Button>Add Route</Button>
            </ModalWithForm>
          }
        />
      )}
      <Toaster />
    </>
  )
}

export default Roots
