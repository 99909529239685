import React from 'react'
// import { ReactComponent as Edit } from "../../asset/icons/Edit.svg";
// import DeleteWithWarning from "../DeleteWithWarning";
import WithModal from '../WithModal'
import {KTCardBody} from '../../_metronic/helpers'
import './custom-table.css'
// import { ReactComponent as CarrotUp } from "../../asset/icons/CarrotUp.svg";
// import { ReactComponent as CarrotDown } from "../../asset/icons/CarrotDown.svg";
// import { ReactComponent as Sort } from "../../asset/icons/Sort.svg";

function TableTemplate(props) {
  const {getTableProps, getTableBodyProps, headerGroups, page, prepareRow} = props

  return (
    <KTCardBody>
      <div className="custom-table-responsive">
        <div className="custom-table " {...getTableProps()} id="table-to-xls">
          <div className="custom-thead">
            {headerGroups.map((headerGroup) => (
              <div className="custom-tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <div className="custom-th" {...column.getHeaderProps()}>
                    <span
                      className={`${
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'table-sort-desc'
                            : 'table-sort-asc'
                          : ''
                      }`}
                      {...column.getSortByToggleProps()}
                    >
                      {column.render('Header')}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="custom-tbody pt-2" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <div className="custom-tr" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <div className="custom-td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </KTCardBody>
  )
}

export default TableTemplate
